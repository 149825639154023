import React from 'react'
import ProfileForm from '../components/ProfileForm'
import '../App.css';


function About() {
  return (
    <div>
      <ProfileForm />
    </div>
  )
}

export default About